export const ImageviewContainer = ({ item, Inventrystyle }) => {

    const stock = item;
    const splitStock = (stock) => {
      if (!stock) return '';
      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');
      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';
      return `${firstChar}${secondChar}`.toUpperCase();
    };

   
   return (
      <div>
        <div style={Inventrystyle} >
          {splitStock(stock)}
        </div>
      </div>
    );
  };
  


  export const Perimumblar = ({ childran,theme }) => {

  
    const styleOneHeader ={ 
      position: 'relative', 
      justifyContent: 'center', 
      alignItems: 'center',
      backgroundColor: theme ? '#1B1B1B59' : '#fff',
  }
    const styleTwoHeader ={
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(13px)',
      zIndex: 1,
    }
    const styleThreeHeader={
      position: 'relative', 
      zIndex: 2, 
      top:80,
      color: theme ? 'white' : 'black',
      fontSize: '14px', 
      textAlign: 'center',

    }
    return (
    <div style={styleOneHeader}>
     <div style={styleTwoHeader}></div>
    <div className="pointer" style={styleThreeHeader}>
      <i className="fa fa-lock me-2" />
      Upgrade & Unlock
    </div> 
{childran}
    </div>
    );
  };
  
